'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

export default function CookieConsent() {
  const pathname = usePathname();

  useEffect(() => {
    const initializeCookieConsent = () => {
      const s = document.createElement('script');
      s.src = 'https://cdn-app5.securiti.ai/consent/cookie-consent-sdk-loader.js';
      s.setAttribute('data-tenant-uuid', 'afd75cd2-cbaa-4d9b-8b84-37598fb2e16f');
      s.setAttribute('data-domain-uuid', '72c272e3-3712-4017-8c2f-07b475397837');
      s.setAttribute('data-backend-url', 'https://app5.securiti.ai');
      s.setAttribute('data-skip-css', 'false');
      s.defer = true;
      
      s.addEventListener('load', () => {
        window.setConsentBannerParams({
          overrideBannerLanguage: {
            'https://onecentral.ae/ar': 'ar',
            'https://onecentral.ae/en': 'en'
          }
        });
        
        if (window.location.href === 'https://onecentral.ae/ar') {
          const style = document.createElement('style');
          style.innerHTML = `
            .cc-compliance .cc-allow {order:3}
            .cc-compliance .cc-dismiss {order:2}
            .cc-compliance .cmp-pref-link {order:1}
          `;
          document.head.appendChild(style);
        }
      });

      document.head.appendChild(s);
    };

    // Remove existing script if any
    const existingScript = document.querySelector('script[src*="cookie-consent-sdk-loader.js"]');
    if (existingScript) {
      existingScript.remove();
    }

    initializeCookieConsent();
  }, [pathname]);

  return null;
} 